<script lang="ts" setup></script>

<template>
  <div>
    <div class="text-center text-lg md:text-2xl">ขออภัย! เกิดข้อผิดพลาดภายในเซิร์ฟเวอร์</div>
    <div class="text-center text-sm md:text-lg text-grey-4">เราขออภัยในความไม่สะดวกที่เกิดขึ้น มีปัญหาในการประมวลผลคำขอของคุณภายในเซิร์ฟเวอร์ของเรา</div>
  </div>
</template>

<style scoped></style>
